import { RouteName } from './types';

export const routes = [
  {
    path: '/:orderNumber',
    component: () => import('../views/Ticket.vue'),
    children: [
      {
        path: '',
        component: () => import('../views/TicketList.vue'),
        name: RouteName.TICKETS,
      },
      {
        path: 'group/:ticketIndex',
        component: () => import('../views/TicketGroupList.vue'),
        name: RouteName.TICKET_GROUP,
      },
      {
        path: ':ticketNumber',
        component: () => import('../views/TicketDetail.vue'),
        name: RouteName.TICKET_DETAIL,
      },
      {
        path: 'checkin',
        component: () => import('../views/checkin/Checkin.vue'),
        children: [
          {
            path: '',
            name: RouteName.CHECKIN,
            component: () => import('../views/checkin/CheckinIndex.vue'),
          },
          {
            path: 'scan',
            name: RouteName.CHECKIN_SCAN,
            component: () => import('../views/checkin/CheckinScan.vue'),
          },
          {
            path: 'confirm',
            name: RouteName.CHECKIN_CONFIRM,
            component: () => import('../views/checkin/CheckinConfirm.vue'),
          },
        ],
      },
      {
        path: 'checkin-result/:checkinGroup',
        name: RouteName.CHECKIN_RESULT,
        component: () => import('../views/CheckinResultIndex.vue'),
      },
      {
        path: 'checkin-result/:checkinGroup/:attendeeId',
        name: RouteName.CHECKIN_RESULT_DETAIL,
        component: () => import('../views/CheckinResultDetail.vue'),
      },
      {
        path: 'checkin-result/:checkinGroup/:attendeeId/:qr',
        name: RouteName.CHECKIN_RESULT_QR_DETAIL,
        component: () => import('../views/CheckinResultQrDetail.vue'),
      },
    ],
  },
  {
    path: '/d/:ticketNumber',
    component: () => import('../views/d/TicketDetail.vue'),
  },
  {
    path: '/',
    component: () => import('../views/404.vue'),
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('../views/404.vue'),
  },
];
